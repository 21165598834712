import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import global_es from 'translation/es/global.json';
import global_en from 'translation/en/global.json';
import global_pt from 'translation/pt/global.json';
import App from './App';

// import reportWebVitals from './reportWebVitals';

import Store from './redux/index';

// // set language
// let inicialLeng = 'en';

// // get browser lenguague
// const browserLeng = window.navigator.language || navigator.browserLanguage;
// if (browserLeng.includes('es') || browserLeng.includes('en') || browserLeng.includes('pt')) {
//   inicialLeng = browserLeng;
// }

// // get localStorage Language
// const localStorageleng = JSON.parse(localStorage.getItem('lang'));
// if (localStorageleng) {
//   inicialLeng = localStorageleng;
// }

// init translator
i18next
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    // lng: inicialLeng,
    supportedLngs: ['en', 'es', 'pt'],
    fallbackLng: 'en',
    resources: {
      es: {
        global: global_es,
      },
      en: {
        global: global_en,
      },
      pt: {
        global: global_pt,
      },
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={Store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
