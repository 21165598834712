import { useState, useCallback, useEffect } from "react";

export const useIsWidthBelow = (width) => {
    const [isWidthBelow, setIsWidthBelow] = useState(window.innerWidth <= width);

    const handleResize = useCallback(() => {
      setIsWidthBelow(window.innerWidth <= width);
    }, [width]);

    useEffect(() => {
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [handleResize]);

    return isWidthBelow;
  };

  export const useMobile = () => useIsWidthBelow(1129.9);