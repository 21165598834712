/* eslint-disable react-hooks/exhaustive-deps */
import Routes from './routes/index';
import FirebaseProvider from './context/firebase';

function App() {
  return (
    <FirebaseProvider
      config={{
        apiKey: 'AIzaSyAPMiAuiZn4k-MCj4zN1tXKVZiWiG9ShQM',
        authDomain: 'smileweb-app.firebaseapp.com',
        databaseURL: 'https://smileweb-app-default-rtdb.firebaseio.com',
        projectId: 'smileweb-app',
        storageBucket: 'smileweb-app.appspot.com',
        messagingSenderId: '886274017613',
        appId: '1:886274017613:web:ed6cbe4ecac6d5976c4f04',
        measurementId: 'G-Y4NJN5SNWJ',
      }}
    >
      <Routes />
    </FirebaseProvider>
  );
}

export default App;
