// dependences
import { useMobile } from 'hooks/handleSize';
import { useLanguage } from 'hooks/i18n';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';

// components
import DesktopMenu from './desktopMenu/presentational';
import MobileMenu from './mobileMenu/presentational';

const Navbar = () => {
  const [t, i18n] = useTranslation('global');
  const [showLanguages, setShowLanguages] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [language, setLanguage] = useLanguage();
  const [advertisingView, setAdvertisingView] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMobile();

  const v1 = window.location.pathname.includes('1') ||  window.location.pathname.includes('2');
  const v2 = window.location.pathname.includes('3') ||  window.location.pathname.includes('4');

  useEffect(() => {
    if(!isMobile) {
      setShowMobileMenu(false);
    }
  }, [isMobile]);

  // list of nav manu
  const navItems = useMemo(() => [
    {
      path: `/${language}/`,
      title: t('header.home'),
      key: 'home',
    },
    {
      path: `/${language}/platform`,
      title: t('header.platform'),
      key: 'platform',
    },
    {
      path: `/${language}/company`,
      title: t('header.company'),
      key: 'company',
    },
    {
      path: `/${language}/academy`,
      title: t('header.academy'),
      key: 'academy',
    },
    {
      path: `/${language}/pricing`,
      title: t('header.pricing'),
      key: 'pricing',
    },
    {
      path: `/${language}/contact`,
      title: t('header.contact'),
      key: 'contact',
    },
  ], [language, t]);

  const navAdvertisingItems = useMemo(() => [
    {
      title: t('header.platform'),
      key: 'platform',
      path: '#platform'
    },
    {
      title: t('header.clients'),
      key: 'clients',
      path: '#clients',
    },
    {
      title: t('header.contact'),
      path: '#contact',
    },
  ], [t])

  useEffect(() => {
    if(window.location.pathname.includes('free-demo-cx')) {
      setAdvertisingView(true);
    } else {
      setAdvertisingView(false);
    }
  }, [location]);


  const languagesList = ['es', 'en', 'pt'];

  // change Language
  const changeLanguage = (language) => {
    const split = location.pathname.split('/');
    split[1] = language;
    history.replace(split.join('/') + location.search)
    setLanguage(language);
    setShowLanguages(false);
    setShowMobileMenu(false);
  };

  const showLanguagesFn = () => {
    setShowLanguages(!showLanguages);
  };

  const sendDemoEvent = () => {
    window.ga('send', 'event', 'button', 'click_button', 'free_demo')
  }

  // mobile functions
  return (
    <>
      <DesktopMenu
        changeLanguage={changeLanguage}
        navItems={location.pathname.includes('free-demo-cx') ? navAdvertisingItems : navItems}
        languagesList={languagesList}
        showLanguages={showLanguages}
        showLanguagesFn={showLanguagesFn}
        i18n={i18n}
        language={language}
        sendDemoEvent={sendDemoEvent}
        advertisingView={advertisingView}
        version2={!v1}
      />
      <MobileMenu
        changeLanguage={changeLanguage}
        navItems={location.pathname.includes('free-demo-cx') ? navAdvertisingItems : navItems}
        languagesList={languagesList}
        showLanguages={showLanguages}
        showLanguagesFn={showLanguagesFn}
        i18n={i18n}
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
        language={language}
        sendDemoEvent={sendDemoEvent}
        advertisingView={advertisingView}
        version2={v2}
      />

    </>
  );
};

export default Navbar;
