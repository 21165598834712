export const types = {
  CHANGE_LENGUAGE: 'CHANGE_LENGUAGE',
};
// state
const initialState = {
  language: 'es',
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LENGUAGE: {
      return {
        ...state,
        language: action.meta,
      };
    }
    default:
      return state;
  }
};
