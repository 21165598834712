import { NavLink } from 'react-router-dom';

const DetermineNavigation = ({ item, css, advertisingView }) => {

    return (
        <>
            {advertisingView
            ? <a href={item.path} className={css.navItem}>{item.title}</a>
            : <NavLink
                to={item.path}
                className={css.navItem}
                activeClassName={css.active}
                exact
            >
                {item.title}
            </NavLink>
            }
        </>

    );
}

export default DetermineNavigation;
