import { Suspense, useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import NotFound from 'views/NotFound/presentational';
import { Loader, Layout } from 'components';
import { useSelector } from 'react-redux';
import ScrollToTop from 'utils/ScrollTop';
import { useHistory, useRouteMatch } from 'react-router';
import { useLanguage } from 'hooks/i18n';
import Routes from './routes';

const Redirector = () => {
  const { roleRoutes = 'public' } = useSelector(({ AppReducer = {} }) => AppReducer);

  const { params } = useRouteMatch();
  const [language, setLanguage] = useLanguage();
  const history = useHistory();

  useEffect(() => {
    if (!params.language) {
      history.replace(`/${language}`);
    }
    if (params.language !== language) {
      setLanguage(params.language);
    }
  }, [params, language, setLanguage, history]);

  return (
    <Switch>
      {Routes[roleRoutes].map(({ key, ...routeProps }) => (
        <Route {...routeProps} key={key} />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

export default function Router() {
  return (
    <Suspense fallback={<Loader fullAppLoader />}>
      <BrowserRouter>
        <Layout>
          <ScrollToTop>
            <Switch>
              <Route path="/:language?/" component={Redirector} />
              <Route component={NotFound} />
            </Switch>
          </ScrollToTop>
        </Layout>
      </BrowserRouter>
    </Suspense>
  );
}
