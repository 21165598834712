/* eslint-disable react/prop-types */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  getDoc,
  getDocs,
  collection,
  setDoc,
  doc,
} from 'firebase/firestore';

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

const context = createContext(null);

function FirebaseProvider({ children, config }) {
  const [currentUser, setCurrentUser] = useState();
  const [authEnabled, setAuthEnabled] = useState(false);
  const app = useMemo(() => (config ? initializeApp(config) : undefined), [config]);
  const db = useMemo(() => (app ? getFirestore() : undefined), [app]);
  const auth = useMemo(() => (app ? getAuth() : undefined), [app]);

  onAuthStateChanged(auth, async (user) => {
    if (!user) {
      setCurrentUser(user);
      return;
    }
    const querySnapshot = await getDoc(doc(db, 'internal', user.uid));
    Object.assign(user, {
      internal: { roles: [], ...querySnapshot.data() },
    });
    setCurrentUser(user);
  });

  const loginWithGoogle = useCallback(() => {
    if (!auth) return;
    (async () => {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    })();
  }, [auth]);

  const logout = useCallback(() => {
    signOut(auth);
  }, [auth]);

  const value = useMemo(() => ({
    config,
    app,
    db,
    authEnabled,
    setAuthEnabled,
    currentUser,
    logout,
    loginWithGoogle,
  }), [config, app, db, authEnabled, currentUser, logout, loginWithGoogle]);

  return (
    <context.Provider value={value}>
      {children}
    </context.Provider>
  );
}

export function useFirebaseContext() {
  return useContext(context);
}

export function useFirebaseApp() {
  const { app } = useFirebaseContext();
  return app;
}

export function useFirebaseDb() {
  const { db } = useFirebaseContext();
  return db;
}

export function useFirebaseCollection(name, options = {}) {
  const [response, setResponse] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const { db } = useFirebaseContext();

  const getData = useCallback(async () => {
    if (!name || !db) return;
    setResponse({
      loading: true,
      data: null,
      error: null,
    });
    try {
      const querySnapshot = await getDocs(collection(db, name));
      const data = [];
      querySnapshot.forEach((item) => {
        const payload = item.data();
        if (payload.enabled === false && !options.all) return;
        data.push({
          id: item.id,
          ...payload,
        });
      });
      setResponse({
        loading: false,
        data,
        error: null,
      });
    } catch (error) {
      setResponse({
        loading: false,
        data: null,
        error,
      });
    }
  }, [db, name]);

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, [getData]);

  const create = useCallback((data) => {
    setDoc(doc(db, name, btoa((new Date()).valueOf())), data);
  }, [db, name]);

  const update = useCallback((id, data) => {
    setDoc(doc(db, name, id), data);
  }, [db, name]);

  return {
    ...response,
    create,
    update,
  };
}

export default FirebaseProvider;
