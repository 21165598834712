import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useCallback } from 'react';

export const useLanguage = () => {
  const [, i18n] = useTranslation();
  const { language } = i18next;

  const setLanguage = useCallback((value) => i18n.changeLanguage(value), [i18n]);

  return [language, setLanguage];
};

export default useLanguage;
