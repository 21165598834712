import { useFirebaseContext } from 'context/firebase';
import { useTranslation } from 'react-i18next';
// import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
// dependences
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// images
import logo from 'assets/header/smileLogo.svg';
import WordIcon from 'assets/header/wordIcon.svg';
import darkLogo from 'assets/header/darkSmileLogo.svg';
// styles
import css from './css.module.scss';
import DetermineNavigation from '../DetermineNavigation';
import Button from 'components/Button';
import clsx from 'clsx';

export const SmartLogin = () => {
  const [t] = useTranslation('global');

  const {
    authEnabled,
    loginWithGoogle,
    currentUser,
    logout,
  } = useFirebaseContext();
  if (authEnabled && currentUser) {
    return (
      <button type="button" onClick={logout}>
        {currentUser.displayName}
      </button>
    );
  }

  if (authEnabled) {
    return (
      <button type="button" onClick={loginWithGoogle}>Admin Sign In</button>
    );
  }
  return (
    <button
      type="button"
      onClick={() => window.open('https://dashboard.smileweb.net')}
    >
      {t('header.sign-in')}
    </button>
  );
};

const DesktopMenu = ({
  changeLanguage,
  i18n,
  language,
  languagesList,
  navItems,
  sendDemoEvent,
  showLanguages,
  showLanguagesFn,
  advertisingView,
  version2,
}) => (
  <div className={clsx(css.container, (advertisingView && version2) && css['dark-container'])}>
    <header>
      <Link to={advertisingView ? "#" : "/"}>
        <img width='100%' height='100%' loading="lazy" className={css.logo} src={(advertisingView && version2) ? darkLogo : logo} alt="smile logo" />
      </Link>
      <nav className={css.center}>
        <ul>
          {navItems.map((item) => (
            <li key={item.key}>
              <DetermineNavigation css={css} item={item} advertisingView={advertisingView} />
            </li>
          ))}

        </ul>
      </nav>
      <div className={css.right}>
        {!advertisingView && <SmartLogin />}
        <Button language={language} onClick={sendDemoEvent}>Free Demo</Button>
        {!advertisingView && <div
          onClick={() => showLanguagesFn()}
          className={css.language}
        >
          <span>
            {i18n.language}
          </span>
          <img width='100%' height='100%' loading="lazy" src={WordIcon} alt="WordIcon" />
        </div>}

        {!advertisingView && <div className={css.LenguagueSelector}>
          {showLanguages && languagesList.map((lang) => (
            <div
              key={lang}
              className={css.languageOption}
              onClick={() => changeLanguage(`${lang}`)}
            >
              <span>
                {lang}
              </span>
              <img width='100%' height='100%' loading="lazy" src={WordIcon} alt="WordIcon" />
            </div>
          ))}
        </div>}

      </div>
    </header>
  </div>
);

DesktopMenu.defaultProps = {
  changeLanguage: () => { },
  showLanguagesFn: () => { },
  showLanguages: false,
};

DesktopMenu.propTypes = {
  changeLanguage: PropTypes.func,
  showLanguagesFn: PropTypes.func,
  navItems: PropTypes.array.isRequired,
  languagesList: PropTypes.array.isRequired,
  showLanguages: PropTypes.bool,
  i18n: PropTypes.any.isRequired,
};
export default DesktopMenu;
