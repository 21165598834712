import PropTypes from 'prop-types';
import { Navbar, Footer } from 'components';
// import FooterView from 'components/Footer/presentational';

function Layout({ children }) {
    return (
        <>
            <Navbar />
            {children}
            <Footer />
        </>
    );
}
Layout.propTypes = {
    children: PropTypes.object.isRequired,
};
export default Layout;
