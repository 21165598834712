import PropTypes from 'prop-types';
// images
import Logo from 'assets/footer/LogoBlueAndWhite.svg';
import Facebook from 'assets/footer/facebookLogo.svg';
import LinkedingLogo from 'assets/footer/linkedingLogo.svg';
import InstagramLogo from 'assets/footer/instagramLogo.svg';
import EmailLogo from 'assets/footer/emailLogo.svg';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
// styles
// import { Link } from 'react-router-dom';
import css from './css.module.scss';
import clsx from 'clsx';

const FooterView = ({ menuList, advertisingView, lang }) => (
  <footer className={css.background}>
    <div className={clsx(css.container, advertisingView && css['simple-container'])}>

      <div className={css.top}>
        <Link
          to={advertisingView ? "#" : "/"}
        >
          <figure className={css.column}>
            <img width='100%' height='100%' loading="lazy" className={css.logo} src={Logo} alt="smile logo" />
          </figure>

        </Link>

        {' '}
        {!advertisingView && menuList.map((menu) => (
          <div key={menu.title} className={`${css.singleMenu} ${css.column}`}>
            <h4>{menu.title}</h4>
            <ul>

              {menu.list.map((item) => (
                <li key={item.name}>
                  <HashLink
                    className={css.menuLabel}
                    to={item.path}
                  >
                    <span>•</span>
                    {' '}
                    {item.name}

                  </HashLink>
                </li>
              ))}
            </ul>
            <ul />
          </div>
        ))}
      </div>

      <div className={css.bottom}>
        {!advertisingView && <span>
            <div>Copyright @2021 Smilenet SAS</div>
          </span>}
        <div className={css.socialMedia}>
          <a href="https://www.facebook.com/Smile.Latam/ ">
            {' '}
            <img width='100%' height='100%' loading="lazy" src={Facebook} alt="facebook" />
          </a>
          <a href={lang === 'pt' ? 'https://www.linkedin.com/company/smile-brasil' : "https://www.linkedin.com/company/smile-latam/mycompany/"}>
            {' '}
            <img width='100%' height='100%' loading="lazy" src={LinkedingLogo} alt="LinkedingLogo" />
          </a>
          <a href="https://www.instagram.com/smile.latam/">
            {' '}
            <img width='100%' height='100%' loading="lazy" src={InstagramLogo} alt="InstagramLogo" />
          </a>
          <a href="mailto:info@smileweb.net">
            {' '}
            <img width='100%' height='100%' loading="lazy" src={EmailLogo} alt="EmailLogo" />
          </a>
        </div>
      </div>
    </div>

  </footer>
);
FooterView.defaultProps = {
  menuList: [],
};

FooterView.propTypes = {
  menuList: PropTypes.array,
};
export default FooterView;
