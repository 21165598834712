import PropTypes from 'prop-types';
import logo from 'assets/header/smileLogo.svg';
import Lightlogo from 'assets/header/lightSmileLogo.svg';
import BlackHamburgerIcon from 'assets/header/blackHamburgerIcon.svg';
import LightHamburgerIcon from 'assets/header/lightHamburgerIcon.svg';
import { Link } from 'react-router-dom';
import WordIcon from 'assets/header/lightWordIcon.svg';
import css from './css.module.scss';
import { SmartLogin } from '../desktopMenu/presentational';
import DetermineNavigation from '../DetermineNavigation';
import clsx from 'clsx';
import darkLogo from 'assets/header/darkSmileLogo.svg';
import Button from 'components/Button';

const MobileMenu = ({
  changeLanguage,
  i18n,
  language,
  languagesList,
  navItems,
  sendDemoEvent,
  setShowMobileMenu,
  showLanguages,
  showLanguagesFn,
  showMobileMenu,
  advertisingView,
  version2,
}) => (
  <div className={clsx(`${css.container} ${showMobileMenu && css.menuActived}`, (advertisingView && version2) && css['dark-container'])}>
    <header style={{alignItems: 'center'}}>
      <div className={css.left}>
        <Link to={advertisingView ? "#" : "/"}>
          <img width='100%' height='100%' loading="lazy" className={css.logo} src={(advertisingView && version2) ? darkLogo : (showMobileMenu ? Lightlogo : logo)} alt="smile logo" />
        </Link>
      </div>


      <div className={css.right} style={{width: advertisingView && ''}}>
        {!advertisingView && <SmartLogin />}
        <Button language={language} onClick={sendDemoEvent} mobile={showMobileMenu}>Free Demo</Button>
        <img width='100%' height='100%' loading="lazy"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
          className={css.hamburgerIcon}
          src={(showMobileMenu || (advertisingView && version2)) ? LightHamburgerIcon : BlackHamburgerIcon}
          alt="menu icon"
        />
      </div>
    </header>

    {showMobileMenu === true && (
      <div className={css.itemContiner}>
        <ul>
          {navItems.map((item) => (
            <li key={item.key}>
              <DetermineNavigation item={item} css={css} advertisingView={advertisingView} />
            </li>
          ))}
          {!advertisingView && <li
            onClick={() => showLanguagesFn()}
            className={css.language}
          >
            <span>
              {i18n.language}
            </span>
            <img width='100%' height='100%' loading="lazy" src={WordIcon} alt="WordIcon" />
          </li>}
          {!advertisingView && <div className={css.LenguagueSelector}>
            {showLanguages && languagesList.map((lang) => (
              <div
                key={lang}
                className={css.languageOption}
                onClick={() => changeLanguage(`${lang}`)}
              >
                <span>
                  {lang}
                </span>
                <img width='100%' height='100%' loading="lazy" src={WordIcon} alt="WordIcon" />
              </div>
            ))}
          </div>}
        </ul>
      </div>
    )}

  </div>
);

MobileMenu.defaultProps = {
  showMobileMenu: false,
  setShowMobileMenu: () => { },
  showLanguagesFn: () => { },
  showLanguages: false,
  changeLanguage: () => { },
};

MobileMenu.propTypes = {
  showMobileMenu: PropTypes.bool,
  setShowMobileMenu: PropTypes.func,
  navItems: PropTypes.array.isRequired,
  i18n: PropTypes.any.isRequired,
  showLanguages: PropTypes.bool,
  showLanguagesFn: PropTypes.func,
  languagesList: PropTypes.array.isRequired,
  changeLanguage: PropTypes.func,
};
export default MobileMenu;
