import { Link } from 'react-router-dom';
import css from './css.module.scss';
import clsx from 'clsx';

const Button = ({ language, onClick, children, large, mobile }) => {
    const advertisingView = window.location.pathname.includes('free-demo-cx');

    return (
        advertisingView
        ? <a href='#contact'>
            <button
              className={clsx(css['free-demo-button'], large && css.large, mobile && css.mobile, advertisingView && css['alone-button'])}
              type="button"
              onClick={false}
            >
                {children}
            </button>
        </a>
        : <Link to={`/${language}/form/free-demo`}>
        <button
          className={clsx(css['free-demo-button'], large && css.large, mobile && css.mobile)}
          type="button"
          onClick={onClick}
        >
          {children}
        </button>
      </Link>
    );
}

export default Button;