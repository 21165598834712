import { lazy } from 'react';

const Home = lazy(() => import('views/Home/container'));
const Platform = lazy(() => import('views/Platform/container'));
const Company = lazy(() => import('views/Company/container'));
const Academy = lazy(() => import('views/Academy/container'));
const Contact = lazy(() => import('views/Contact/container'));
const SinglePost = lazy(() => import('views/SinglePost/container'));
const Admin = lazy(() => import('views/Admin'));
const EditPost = lazy(() => import('views/EditPost'));
const Pricing = lazy(() => import('views/Pricing/container'));
const StatusSection = lazy(() => import('views/StatusSection/container'));
const IncidentHistory = lazy(() => import('views/IncidentHistory/container'));
const PlanForm = lazy(() => import('views/PlanForm/container'));
const Advertising = lazy(() => import('views/Advertising/container'))

const TermsAndConditions = lazy(() => import('views/TermsAndConditions/container'))
const PrivacyPolicy = lazy(() => import('views/PrivacyPolicy/container'))

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  public: [
    {
      path: '/:language/terms',
      key: 'status',
      exact: true,
      component: TermsAndConditions,
    },
    {
      path: '/:language/privacy',
      key: 'status',
      exact: true,
      component: PrivacyPolicy,
    },
    {
      path: ['/:language/'],
      key: 'home',
      component: Home,
      exact: true,
      title: 'home',
    },
    {
      path: '/:language/platform',
      key: 'platform',
      component: Platform,
      exact: true,
      title: 'platform',
    },
    {
      path: '/:language/company',
      key: 'company',
      component: Company,
      exact: true,
      title: 'Company',
    },
    {
      path: '/:language/academy',
      key: 'academy',
      component: Academy,
      exact: true,
      title: 'Academy',
    },
    {
      path: '/:language/contact',
      key: 'contact',
      component: Contact,
      exact: true,
      title: 'contact',
    },
    {
      path: '/:language/post/:id',
      key: 'post',
      component: SinglePost,
      exact: true,
      title: 'post',
    },
    {
      path: '/:language/admin',
      key: 'admin',
      exact: true,
      component: Admin,
    },
    {
      path: '/:language/admin/post/:id',
      key: 'admin',
      exact: true,
      component: EditPost,
    },
    {
      path: '/:language/pricing',
      key: 'pricing',
      exact: true,
      component: Pricing,
    },
    {
      path: '/:language/status',
      key: 'status',
      exact: true,
      component: StatusSection,
    },
    {
      path: '/:language/incident-history',
      key: 'history',
      exact: true,
      component: IncidentHistory,
    },
    {
      path: '/:language/form/:plan',
      key: 'form',
      exact: true,
      component: PlanForm,
    },
    {
      path: '/:language/free-demo-cx-:version',
      // key: 'advertising',
      exact: true,
      component: Advertising,
    },
  ],
};
