import { useLanguage } from 'hooks/i18n';
import { useTranslation } from 'react-i18next';
import FooterView from './presentational';

const Footer = () => {
  const [t] = useTranslation('global');
  const [language] = useLanguage();

  const menuList = [
    {
      title: t('footer.platform.title'),
      list: [
        {
          name: t('footer.platform.dataCapture'),
          path: `/${language}/platform#datacapture`,
        },
        {
          name: t('footer.platform.dataAnalytics'),
          path: `/${language}/platform#dataanalytic`,
        },
        {
          name: t('footer.platform.actionANdFollowUp'),
          path: `/${language}/platform#acction`,
        },
        {
          name: 'Terms and Conditions',
          path: `/${language}/terms`,
        },
        {
          name: 'Privacy Policy',
          path: `/${language}/privacy`,
        },
      ],
    },
    {
      title: t('footer.company.title'),
      list: [
        {
          name: t('footer.company.aboutSmile'),
          path: `/${language}/company#aboutus`,
        },
        {
          name: t('footer.company.joinUs'),
          path: `/${language}/company#joinus`,
        },
        {
          name: t('footer.company.beacomeAPartner'),
          path: `/${language}/company#partner`,
        },
      ],
    },
    {
      title: t('footer.academy.title'),
      list: [
        {
          name: t('footer.academy.CustomerExperience'),
          path: `/${language}/academy?category=CX`,
        },
        {
          name: t('footer.academy.EmployeeExperience'),
          path: `/${language}/academy?category=EX`,
        },
        {
          name: t('footer.academy.BrandExperience'),
          path: `/${language}/academy?category=BX`,
        },
        {
          name: t('footer.academy.NPS'),
          path: `/${language}/academy?category=NPS`,
        },
        {
          name: t('footer.academy.CSAT'),
          path: `/${language}/academy?category=CSAT`,
        },
        {
          name: t('footer.academy.CES'),
          path: `/${language}/academy?category=CES`,
        },
      ],
    },
  ];

  const advertisingView = window.location.pathname.includes('free-demo-cx');

  return (
    <FooterView
      item="hello"
      menuList={menuList}
      advertisingView={advertisingView}
      lang={language}
    />
  );
};

export default Footer;
